<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th sort-key="">STO Doc. Num.</vs-th>
                <vs-th sort-key="">External Code</vs-th>
                <vs-th sort-key="">Transaction Date</vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                <vs-th sort-key="">Source Territory</vs-th>
                <vs-th sort-key="">Dest. Territory</vs-th>
                <vs-th sort-key="">Source Warehouse</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total QTY (UOM)</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye" title="Detail"
                                style="margin-right: 5px" @click="handleDetail(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                                v-if="status === 'Draft'" title="Complete" @click="handleComplete(tr.id)" />
                            <vs-button size="small" color="#bb65b0" icon-pack="feather" icon="icon-refresh-ccw"
                                v-if="status === 'Complete'" title="Reversal" @click="handleReversal(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.code }}</vs-td>
                    <vs-td>{{ tr.external_code }}</vs-td>
                    <vs-td>{{ tr.date }}</vs-td>
                    <vs-td>{{ tr.supplier_code }} {{ tr.supplier_name }}</vs-td>
                    <vs-td>{{ tr.src_territory_code }} {{ tr.src_territory_name }}</vs-td>
                    <vs-td>{{ tr.dest_territory_code }} {{ tr.dest_territory_name }}</vs-td>
                    <vs-td>{{ tr.src_warehouse_code }} {{ tr.src_warehouse_name }}</vs-td>
                    <vs-td>{{ tr.dest_warehouse_code }} {{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.quantity_uom }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
import Swal from 'sweetalert2'
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Complete",
        },
    },
    data() {
        return {
            action: null,
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            reversalDate: null,
            reversalNote: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/inbound/simple-sto-stock-in", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        source_warehouse_id: this.sourceWarehouseId,
                        status: this.status,
                        transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleComplete(id) {
            this.deleteId = id
            this.action = "Complete"
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to complete this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/inbound/simple-sto-stock-in/stock-in/" + this.deleteId + "/" + this.action)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully executed",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleDetail(id) {
            this.$router.push({
                name: "inbound.simple-sto-stock-in-detail",
                params: { id: id },
            });
        },
        handleReversal(id) {
            // this.$router.push({
            //     name: "inbound.simple-sto-stock-in-reversal",
            //     params: { id: id },
            // });

            (async () => {
                const { value: date } = await Swal.fire({
                    title: "Select reversal date",
                    input: "date",
                    didOpen: () => {
                        const today = (new Date()).toISOString();
                        Swal.getInput().min = today.split("T")[0];
                    }
                });
                if (date) {
                    this.reversalDate = date;
                    // console.log("tanggal berapa reversal nya : ", this.reversalDate);
                    (async () => {
                        const { value: text } = await Swal.fire({
                            input: "textarea",
                            inputLabel: "Reversal Reason",
                            inputPlaceholder: "Type the reversal reason here...",
                            inputAttributes: {
                                "aria-label": "Type the reversal reason here"
                            },
                            showCancelButton: true
                        });
                        if (text) {
                            this.deleteId = id
                            this.action = "Reversal"
                            this.reversalNote = text

                            this.$vs.loading();
                            this.$http
                                .post("/api/wms/v1/inbound/simple-sto-stock-in/stock-in/" + this.deleteId + "/" + this.action, {
                                    reversal_date: this.reversalDate,
                                    reversal_note: this.reversalNote,
                                })
                                .then((resp) => {
                                    if (resp.code == 200) {
                                        this.$vs.notify({
                                            color: "success",
                                            title: "Success",
                                            text: "The data was successfully reversed",
                                            position: "top-right",
                                            iconPack: "feather",
                                            icon: "icon-x-circle",
                                        });
                                    } else {
                                        this.$vs.notify({
                                            color: "danger",
                                            title: "Error",
                                            text: resp.message,
                                            position: "top-right",
                                            iconPack: "feather",
                                            icon: "icon-x-circle",
                                        });
                                    }
                                    this.$vs.loading.close();
                                    this.getData();
                                })
                                .catch((error) => {
                                    this.$vs.loading.close();
                                    console.log(error);
                                });
                        } else {
                            this.reversalDate = null
                            this.reversalNote = null
                            this.deleteId = null
                        }
                    })()
                }
            })()
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        onchangeSelectedRouteAssignmentToAssign(e, index, row) {
            if (!this.checked[index]) {
                var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
                    return d.id == row.id;
                });

                if (fitered.length == 0) {
                    this.selectedRouteAssignmentToAssign.push(row);
                }
            } else {
                this.selectedRouteAssignmentToAssign.forEach((val, i) => {
                    if (val.id == row.id) {
                        this.selectedRouteAssignmentToAssign.splice(i, 1);
                    }
                });
                this.checkedAll = false;
            }
        },
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
  