<template>
    <vx-card title="Simple Stock Transfer Order (Stock In)">
        <div class="vx-input-group flex">
            <vs-tabs>
                <!-- <vs-tab label="Draft">
                    <tab-draft></tab-draft>
                </vs-tab> -->
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
                <vs-tab label="Reversal">
                    <tab-reversal></tab-reversal>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabDraft from "./TabDraft.vue";
import TabComplete from "./TabComplete.vue";
import TabReversal from "./TabReversal.vue";
export default {
    components: {
        TabDraft,
        TabComplete,
        TabReversal,
    },
    data() {
        return {
            activeTab: "Draft",
        }
    },
    methods: {

    }
}
</script>